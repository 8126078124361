import styles from './FormDescription.module.scss'
import React from 'react'

function FormDescription({
  finsovetnik,
  bindingProduct,
  disEmail,
  disFio,
  disPhone
}) {
  const isIpOrAck = process.env.REACT_APP_IS_IP_OR_ACK === 'true'

  return (
    <>
      {!bindingProduct ? (
        <div data-testid="form-title" className={styles.title}>
          {finsovetnik ? (
            disEmail || disFio || disPhone ? (
              <>
                <div
                  data-testid="finsovetnik-data-filled"
                  className={styles.boldTitle}
                >
                  Ваши данные заполнены с финсоветник.рф
                </div>
                <div data-testid="finsovetnik-access-info">
                  Это сделано для того, чтобы доступ к купленному продукту сразу
                  отобразился в нужном аккаунте.
                </div>
              </>
            ) : (
              <>
                <div data-testid="finsovetnik-fill-data">
                  Заполните все данные верно. Мы отправим вам письмо и смс для
                  подтверждения оплаты.
                </div>
                <div
                  data-testid="finsovetnik-login-info"
                  className={styles.boldTitle}
                >
                  Используйте эти данные для входа на финсоветник.рф
                </div>
              </>
            )
          ) : disEmail || disFio || disPhone ? (
            <>
              <div
                data-testid="profinansy-data-filled"
                className={styles.boldTitle}
              >
                Ваши данные заполнены с profinansy.ru
              </div>
              <div data-testid="profinansy-access-info">
                Это сделано для того, чтобы доступ к купленному продукту сразу
                отобразился в нужном аккаунте.
              </div>
            </>
          ) : isIpOrAck ? (
            <>
              <div data-testid="ip-ack-fill-data">
                Заполните все данные верно. Мы отправим вам письмо и смс для
                подтверждения оплаты.
              </div>
            </>
          ) : (
            <>
              <div data-testid="profinansy-fill-data">
                Заполните все данные верно. Мы отправим вам письмо и смс для
                подтверждения оплаты.
              </div>
              <div
                data-testid="profinansy-login-info"
                className={styles.boldTitle}
              >
                Используйте эти данные для входа на profinansy.ru
              </div>
            </>
          )}
        </div>
      ) : (
        <div data-testid="card-binding-info" className={styles.descriptionFS}>
          Для привязки карты мы спишем 1 рубль и сразу вернем
        </div>
      )}
    </>
  )
}

export default FormDescription
