import { getCurrencySymbol } from '../../../../utils/getCurrencySymbol'
import { getImageFormat } from '../../../../utils/getImageFormat'
import star from '../../../../icons/starIcon.svg'
import styles from './SpecialOffer.module.scss'
import React from 'react'

const SpecialOffer = ({
  specialOffer,
  setCheckedSpecialOffer,
  checkedSpecialOffer,
  handlePromo
}) => {
  const handleClick = (e) => {
    e.preventDefault()

    setCheckedSpecialOffer(e.target.checked)
    if (handlePromo) handlePromo(e.target.checked)
  }

  return (
    <div data-testid="specialOffer-wrapper" className={styles.wrapper}>
      <div data-testid="specialOffer-header" className={styles.header}>
        <img src={star} alt="icon" data-testid="specialOffer-icon" />
        <p>Специальное предложение для вас!</p>
      </div>

      <div data-testid="specialOffer-content" className={styles.content}>
        <div data-testid="specialOffer-mainInfo" className={styles.mainInfo}>
          <div data-testid="specialOffer-leftSide" className={styles.leftSide}>
            {specialOffer?.image_url && (
              <img
                src={getImageFormat(specialOffer.image_url, '_')}
                className={styles.img}
                alt="file"
                data-testid="specialOffer-img"
              />
            )}
            <div data-testid="specialOffer-title" className={styles.title}>
              {specialOffer.name || specialOffer.product_info.name}
            </div>
          </div>
          <div data-testid="specialOffer-price" className={styles.price}>
            {specialOffer.price?.toLocaleString('ru-RU') ||
              specialOffer.product_info.price?.toLocaleString('ru-RU')}{' '}
            {getCurrencySymbol(specialOffer.product_info.currency)}
          </div>
        </div>

        <div data-testid="specialOffer-divider" className={styles.divider} />

        <div
          data-testid="specialOffer-description"
          className={styles.description}
        >
          {specialOffer.description}
        </div>
      </div>

      <label className={styles.checkboxLabel}>
        <input
          type="checkbox"
          checked={checkedSpecialOffer}
          onChange={(e) => handleClick(e)}
          className={styles.checkboxInput}
        />
        <span className={styles.checkboxText}>
          {checkedSpecialOffer ? 'Удалить из заказа' : 'Добавить к заказу'}
        </span>
      </label>
    </div>
  )
}

export default SpecialOffer
