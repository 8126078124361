import LoginViaProfinancy from '../LoginViaProfinancy/LoginViaProfinancy'
import ForgotPasswordForm from '../ForgotPasswordForm/ForgotPasswordForm'
import SignUpForm from '../SignUpFormV2/SignUpForm'
import styles from './LoginForm.module.scss'
import React, { useState } from 'react'

const LoginForm = ({ setLoginModalActive, setUserKey }) => {
  const [type, setType] = useState('login')

  const renderForm = (type) => {
    switch (type) {
      case 'login':
        return (
          <LoginViaProfinancy
            setType={setType}
            setLoginModalActive={setLoginModalActive}
            setUserKey={setUserKey}
          />
        )
      case 'signup':
        return (
          <SignUpForm
            setLoginModalActive={setLoginModalActive}
            setUserKey={setUserKey}
          />
        )
      case 'forgotPass':
        return (
          <ForgotPasswordForm
            setType={setType}
            setLoginModalActive={setLoginModalActive}
            setUserKey={setUserKey}
          />
        )
    }
  }

  return (
    <section data-testid="login-page" className={styles['login-page']}>
      <div data-testid="login-wrapper" className="wrapper">
        <div data-testid="login-container" className={styles.container}>
          <div
            data-testid="form-container"
            className={styles['form-container']}
          >
            {renderForm(type)}
          </div>
        </div>
      </div>
    </section>
  )
}

export default LoginForm
