const isClient = typeof window !== 'undefined';

// '_': { 'w': 0, 'h': 0, 't': 'h', 'crop': False }, # Оригинальный размер для webp
// 'T': { 'w': 32,  'h': 32,  't': 'h', 'crop': False },
// 'A': { 'w': 720, 'h': 180, 't': 'w' }, # Картинки к компании, блоги ...
// '4': { 'w': 40,  'h': 40,  't': 'h', 'crop': False },
// '8': { 'w': 80,  'h': 80,  't': 'h', 'crop': False },
// 'a': { 'w': 180,  'h': 180, 't': 'h', 'crop': False }, # Крупный аватар
// 'B': { 'w': 234, 'h': 322, 't': 'h' },  # для блогов/новостей
// 'b': { 'w': 510, 'h': 380, 't': 'h' },  # блоги на первой странице
// 'S': { 'w': 720, 'h': 1560, 't': 'w', 'crop': False }, # Stories
// 'L': { 'w': 772, 'h': 400, 't': 'h' }, # Лента
// 'Y': {'w': 720, 'h': 316, 't': 'h'},  # Картинка для Видео YouTube
// 'y': {'w': 516, 'h': 242, 't': 'h'},  # Картинка для Видео YouTube
// 'C': {'w': 704, 'h': 400, 't': 'h'},  # Картинки в списке событий клуба

let isCanWepP = null;
export const getImageFormat = (imageUrl, size) => {
  if (!imageUrl || !size) return '';
  if (isCanWepP === null) canUseWebP();
  const rg = /(.+)\/(.+)\.(.+)/;
  const [, path, name, extension] = rg.exec(imageUrl);

  if (isCanWepP) {
    return `${path}/${name}-${size}W.webp`;
  }
  return `${path}/${name}-${size}.${extension}`;
};

function canUseWebP() {
  if (!isClient) return true;
  const elem = document.createElement('canvas');
  if (elem.getContext && elem.getContext('2d')) {
    // was able or not to get WebP representation
    isCanWepP = elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
    return;
  }
  // very old browser like IE 8, canvas not supported
  isCanWepP = false;
}
