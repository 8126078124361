import React from 'react'

import styles from './Checkbox.module.scss'

const Checkbox = ({ checked, onChange, ...restProps }) => {
  return (
    <div className={styles.wrapper} onClick={onChange} {...restProps}>
      <div
        className={checked ? `${styles.check} ${styles.active}` : styles.check}
        style={{ background: checked ? '#4285F4' : '#FFFFFF' }}
      >
        {checked && (
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.4549 7.41062L1.1639 4.77763L0.399902 5.65562L3.4549 9.16562L9.9999 1.64363L9.2359 0.765625L3.4549 7.41062Z"
              fill="white"
            />
          </svg>
        )}
      </div>
    </div>
  )
}

export default Checkbox
