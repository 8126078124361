import { getPhoneFormat } from '../../../../utils/getPhoneFormat'
import { PatternFormat } from 'react-number-format'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styles from './Inputs.module.scss'

function PhoneInput({ phone, setPhone, disPhone, setInputsError }) {
  const [phoneDirty, setPhoneDirty] = useState(false)
  const [phoneError, setPhoneError] = useState('Телефон не может быть пустым')
  const [format, setFormat] = useState('+#(###)###-##-##')
  const [authPhone, setAuthPhone] = useState(false)

  const location = useLocation()
  const getPhone = new URLSearchParams(location.search).get('phone')

  useEffect(() => {
    if (getPhone) {
      if (getPhone.slice(1, 2) !== '7') {
        setPhone(
          `+${getPhone
            .slice(1, getPhone.length)
            .replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1' + '-')}`
        )
        setAuthPhone(true)
        setPhoneError('')
        setInputsError((prev) => ({ ...prev, phone: false }))
      } else {
        setPhone(getPhone)
        setAuthPhone(false)
        setPhoneError('')
        setInputsError((prev) => ({ ...prev, phone: false }))
      }
    }

    if (disPhone && disPhone?.length > 9) {
      if (disPhone.slice(0, 1) !== '7') {
        setPhone(
          `+${disPhone.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1' + '-')}`
        )
        setAuthPhone(true)
        setPhoneError('')
        setInputsError((prev) => ({ ...prev, phone: false }))
      } else {
        setPhone(disPhone)
        setAuthPhone(false)
        setPhoneError('')
        setInputsError((prev) => ({ ...prev, phone: false }))
      }
    }
  }, [getPhone, disPhone])

  function phoneHandler(e) {
    setPhone(e.target.value)
    const re = /^([+]?[0-9\s-\(\)]{11,25})*$/i
    if (!re.test(String(e.target.value))) {
      setPhoneError('Некорректный номер телефона')
      setInputsError((prev) => ({ ...prev, phone: true }))
    } else if (e.target.value.length === 0) {
      setPhoneError('Телефон не может быть пустым')
      setInputsError((prev) => ({ ...prev, phone: true }))
      setAuthPhone(false)
    } else {
      setPhoneError('')
      setInputsError((prev) => ({ ...prev, phone: false }))
    }
    getPhoneFormat(e.target.value, setFormat)
  }

  function blurHandler(e) {
    if (e.target.name === 'phone') {
      setPhoneDirty(true)
    }
  }

  function clearInp(e) {
    if (e.key === 'Backspace' || e.target.value) {
      setPhone('')
      setAuthPhone(false)
      setPhoneError('Телефон не может быть пустым')
      setInputsError((prev) => ({ ...prev, phone: true }))
    }
  }

  return (
    <div data-testid="phone-input-box" className={styles.inputBox}>
      <label data-testid="phone-label" id="phone-label" htmlFor="phone-input">
        Телефон
      </label>

      {authPhone ? (
        <input
          data-testid="phone-input"
          id="phone-input"
          onChange={(e) => phoneHandler(e)}
          value={phone}
          onBlur={(e) => blurHandler(e)}
          name="phone"
          className={`${styles.input} ${!phoneError && styles.inputSuccess}`}
          type="tel"
          required
          disabled={disPhone}
          onKeyDown={(e) => clearInp(e)}
        />
      ) : (
        <PatternFormat
          data-testid="pattern-phone-input"
          id="pattern-phone-input"
          format={format}
          mask="_"
          onChange={(e) => phoneHandler(e)}
          value={phone}
          onBlur={(e) => blurHandler(e)}
          name="phone"
          className={`${styles.input} ${!phoneError && styles.inputSuccess}`}
          type="text"
          required
          disabled={disPhone}
          placeholder="Например: +7(912)345-67-89"
        />
      )}
      {phoneDirty && phoneError && (
        <div data-testid="phone-error-text" className={styles.errorText}>
          {phoneError}
        </div>
      )}
    </div>
  )
}

export default PhoneInput
