import styles from './Thanks.module.scss'
import React from 'react'

function Success() {
  const isIpOrAck = process.env.REACT_APP_IS_IP_OR_ACK === 'true'
  return (
    <div
      data-testid="modal-thanks"
      className={`${styles.modalThanks} ${styles.active}`}
    >
      <div
        data-testid="modal-content-thanks"
        className={`${styles.modal__contentThanks} ${styles.active}`}
      >
        {!isIpOrAck && (
          <img
            data-testid="logo"
            src="/logo.png"
            alt=""
            className={styles.logoThanks}
          />
        )}
        <div data-testid="main-thanks" className={styles.mainThanks}>
          <div data-testid="header-thanks" className={styles.headerThanks}>
            Спасибо!
          </div>
          <div data-testid="title-thanks" className={styles.titleThanks}>
            Ваш заказ оплачен
          </div>
          <div data-testid="email-thanks" className={styles.emailThanks}>
            Письмо-подтверждение отправлено на электронную почту
          </div>
          <img
            data-testid="success-logo"
            src="/thanks.png"
            alt=""
            className={styles.successLogo}
          />
          <div data-testid="check-email" className={styles.titleThanks}>
            Проверьте свою почту
          </div>

          {!isIpOrAck && (
            <div data-testid="footer-thanks" className={styles.footerThanks}>
              <p data-testid="footer-text" className={styles.footerText}>
                Если вы не получили письма,
              </p>
              <a
                data-testid="support-link"
                href="https://t.me/profinansy_help_bot"
                className={styles.support}
              >
                свяжитесь с нами
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Success
