import ShowPasswordIcon from '../../../icons/login/ShowPasswordIcon'
import { changePassword } from '../../../store/actions/user&balance'
import Typography from '../components/Typography/Typography'
import FormInput from '../components/FormInput/FormInput'
import { InitialValues, SigninSchema } from './formik'
import styles from './ResetPasswordForm.module.scss'
import React, { useState, useEffect } from 'react'
import Button from '../components/Button/Button'
import ReactCodeInput from 'react-code-input'
import 'react-phone-number-input/style.css'
import { useFormik } from 'formik'

const ResetPasswordForm = ({
  login,
  recoverFormik,
  setType,
  setLoginModalActive,
  setUserKey
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const [showRepeatPassword, setShowRepeatPassword] = useState(false)
  const [repeatPassVal, setRepeatPassVal] = useState('')

  const [error, setError] = useState({})
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    formik.setFieldValue('login', login)
  }, [])

  const urlOrgin = new URL(window.location.href).origin
  const finsovetnik =
    urlOrgin === process.env.REACT_APP_PROD_FA_URL ||
    urlOrgin === process.env.REACT_APP_TEST_FA_URL

  const formik = useFormik({
    initialValues: InitialValues,
    validationSchema: SigninSchema,
    onSubmit: async (values) => {
      try {
        const result = await changePassword(values, finsovetnik)
        localStorage.setItem('token', result.data.token)
        setUserKey(result.data.ukey)
        setLoginModalActive(false)
        setIsError(false)
      } catch (err) {
        setError(err.response)
        setIsError(true)
      }
    },
    validateOnMount: true
  })

  const showPassMatchError = () => {
    if (formik.values.pass && repeatPassVal) {
      return formik.values.pass !== repeatPassVal
    }
  }

  const handleButtonDisable = () => {
    return (
      Boolean(Object.keys(formik.errors)?.length) ||
      formik.values.pass !== repeatPassVal
    )
  }

  const [counter, setCounter] = useState(59)

  useEffect(() => {
    let timer
    if (counter > 0) {
      setTimeout(() => setCounter(counter - 1), 1000)
    }
    return () => clearInterval(timer)
  }, [counter])

  const preventCopyPaste = (e) => {
    e.preventDefault()
  }

  return (
    <section
      data-testid="reset-password-page"
      className={styles['reset-password-page']}
    >
      <div
        data-testid="reset-form-container"
        className={styles['form-container']}
      >
        <Typography
          data-testid="reset-form-title"
          as="h4"
          className={styles.title}
        >
          Создайте новый пароль
        </Typography>
        <Typography
          data-testid="reset-form-description"
          as="p"
          color="#898989"
          className={styles.description}
        >
          Для смены пароля вам необходимо ввести новый пароль и код
          подтверждения, который мы отправили на ваш почтовый адрес
        </Typography>
        <div data-testid="reset-form-fields" className={styles['form-fields']}>
          <div
            data-testid="reset-password-input-wrapper"
            className={styles['password-input-wrapper']}
          >
            <FormInput
              data-testid="reset-password-input"
              id="password"
              type={showPassword ? 'text' : 'password'}
              name="password"
              label="Password"
              placeholder="Пароль"
              className={
                showPassMatchError()
                  ? `${styles.input} ${styles.password} ${styles.invalid}`
                  : `${styles.input} ${styles.password}`
              }
              onChange={(e) => {
                formik.setFieldValue('pass', e.target.value)
              }}
            />
            <div
              data-testid="reset-show-password-icon"
              className={styles['show-password-icon']}
              onClick={() => setShowPassword((prev) => !prev)}
            >
              <ShowPasswordIcon show={showPassword} />
            </div>
          </div>
          <div
            data-testid="reset-password-input-wrapper"
            className={styles['password-input-wrapper']}
          >
            <FormInput
              data-testid="reset-repeat-password-input"
              id="repeatPassword"
              type={showRepeatPassword ? 'text' : 'password'}
              name="repeatPassword"
              label="Repeat Password"
              placeholder="Повторите пароль"
              className={
                showPassMatchError()
                  ? `${styles.input} ${styles.password} ${styles.invalid}`
                  : `${styles.input} ${styles.password}`
              }
              onChange={(e) => {
                setRepeatPassVal(e.target.value)
              }}
              onCopy={preventCopyPaste}
              onPaste={preventCopyPaste}
              onCut={preventCopyPaste}
            />
            <div
              data-testid="resetrepeat-password-icon"
              className={styles['show-password-icon']}
              onClick={() => setShowRepeatPassword((prev) => !prev)}
            >
              <ShowPasswordIcon show={showRepeatPassword} />
            </div>
          </div>
        </div>
        {showPassMatchError() && (
          <Typography
            data-testid="reset--mismatch-error"
            className={styles['code-invalid']}
            color="#F84708"
          >
            Пароли не совпадают
          </Typography>
        )}
        <div data-testid="reset-code-input-section" className={styles.code}>
          <Typography
            data-testid="reset-code-title"
            as="p"
            color="#898989"
            className={styles['code-title']}
          >
            Код из письма
          </Typography>
          <ReactCodeInput
            data-testid="reset-code-input"
            className={
              isError
                ? `${styles.invalid} ${styles['code-input-container']}`
                : styles['code-input-container']
            }
            fields={6}
            name="code"
            inputMode="numeric"
            onChange={(e) => {
              formik.setFieldValue('code', e)
            }}
          />
          <div
            data-testid="reset-timer"
            className={styles.timer}
          >{`00:${`0${counter}`.slice(-2)}`}</div>
          {isError && (
            <Typography
              data-testid="reset-code-error"
              className={styles['code-invalid']}
              color="#F84708"
            >
              {error.data.code === 403012 && 'Код введен неверно'}
            </Typography>
          )}
          <div
            data-testid="reset-resend-code"
            className={
              counter > 0
                ? `${styles.disabled} ${styles['code-send-again']}`
                : styles['code-send-again']
            }
            onClick={() => {
              if (counter === 0) {
                setCounter(59)
                recoverFormik.handleSubmit()
              }
            }}
          >
            <Typography color="#4285F4">Отправить код повторно</Typography>
          </div>
        </div>
        <Button
          data-testid="reset-submit-button"
          type="primary"
          width="100%"
          backgroundColor="#4285F4"
          onClick={formik.handleSubmit}
          isDisabled={handleButtonDisable()}
        >
          <Typography className={styles['button-text']}>
            Установить новый пароль
          </Typography>
        </Button>
        <div data-testid="reset-footer" className={styles.footer}>
          <Typography data-testid="reset-no-account-text" color="#C0C0C0">
            Нет аккаунта?
          </Typography>
          <button
            data-testid="reset-signup-button"
            className={styles.login}
            onClick={() => setType('signup')}
            type="button"
          >
            <Typography color="#4285F4">Зарегистрируйтесь</Typography>
          </button>
        </div>
      </div>
    </section>
  )
}

export default ResetPasswordForm
