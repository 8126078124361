import successMark from '../../icons/successMark.svg'
import styles from './Thanks.module.scss'
import React from 'react'

function CardSuccess() {
  return (
    <div
      data-testid="modal-thanks"
      className={`${styles.modalThanks} ${styles.active}`}
    >
      <div
        data-testid="modal-content-thanks"
        className={`${styles.modal__contentThanks} ${styles.active}`}
      >
        <div data-testid="main-content" className={styles.mainContent}>
          <div data-testid="main-box" className={styles.mainBox}>
            <img
              data-testid="success-icon"
              src={successMark}
              alt=""
              className={styles.successIcon}
            />
            <div data-testid="main-text" className={styles.mainText}>
              Карта успешно привязана
            </div>
            <div data-testid="sub-text" className={styles.subText}>
              Вы можете продолжить работу
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardSuccess
