import LoginForm from '../LoginForm/LoginForm'
import styles from './LoginModal.module.scss'
import React, { useEffect } from 'react'

function LoginModal({ active, setLoginModalActive, setUserKey }) {
  return (
    <div
      data-testid="login-modal"
      className={
        active ? `${styles.modal} ${styles.active_modal}` : `${styles.modal}`
      }
    >
      <div
        data-testid="login-modal-content"
        className={
          active
            ? `${styles.modal_content} ${styles.active}`
            : `${styles.modal_content}`
        }
        onClick={(e) => e.stopPropagation()}
      >
        <LoginForm
          setLoginModalActive={setLoginModalActive}
          setUserKey={setUserKey}
        />
      </div>
    </div>
  )
}

export default LoginModal
