import {
  deleteBalanceResult,
  getBalanceResult
} from '../../store/actions/user&balance'
import React, { useState, useEffect, useCallback } from 'react'
import warning from '../../icons/attention.svg'
import { useParams } from 'react-router-dom'
import styles from './Balance.module.scss'

function Balance({
  promoRes,
  setPromoRes,
  checkedProducts,
  userBalance,
  oldPrice,
  productAmount,
  ukey,
  balance_reserve_id,
  setBalance_reserve_id,
  setBalance_summ,
  payment_method_id,
  cloud
}) {
  const [balance, setBalance] = useState('')
  const [balanceError, setBalanceError] = useState(false)
  const [balanceSuc, setBalanceSuc] = useState(false)
  const [error, setError] = useState('')
  const [availableSum, setAvailableSum] = useState(0)
  const [disabled, setDisabled] = useState(false)

  const { id } = useParams()

  const calculateAvailableSum = useCallback(() => {
    const minBalance = payment_method_id !== cloud ? 101 : productAmount
    const newAvailableSum =
      oldPrice - userBalance >= minBalance ? userBalance : oldPrice - minBalance
    setAvailableSum(newAvailableSum < 0 ? 0 : newAvailableSum)
    setBalance(newAvailableSum < 0 ? '' : newAvailableSum)
  }, [userBalance, oldPrice, productAmount, payment_method_id, cloud])

  useEffect(() => {
    calculateAvailableSum()
  }, [calculateAvailableSum])

  useEffect(() => {
    if (promoRes.success) {
      setBalanceError(false)
      setBalanceSuc(true)
    } else if (promoRes === 'Reserve failed') {
      setError('Не удалось списать баланс')
      setBalanceError(true)
      setBalanceSuc(false)
    }
  }, [promoRes])

  useEffect(() => {
    if (balance > availableSum) {
      setDisabled(true)
      setBalanceError(true)
      setError(
        `Максимальная сумма списания: ${availableSum?.toLocaleString('ru-RU') || 0} ₽.`
      )
    } else {
      setDisabled(false)
      setBalanceError(false)
      setError('')
    }
  }, [balance, availableSum])

  useEffect(() => {
    setBalance_reserve_id(promoRes.reserve_id ?? '')
    setBalance_summ(promoRes.discount ?? 0)
  }, [promoRes, setBalance_reserve_id, setBalance_summ])

  const handleBalance = async () => {
    const res = await getBalanceResult(
      ukey,
      balance,
      id,
      checkedProducts,
      payment_method_id
    )
    setPromoRes(res?.data.result)
  }

  const cancelBalance = async () => {
    await deleteBalanceResult(balance_reserve_id)
    setBalanceError(false)
    setBalanceSuc(false)
    setPromoRes({})
  }

  return (
    <div data-testid="main-balance" className={styles.mainBalance}>
      <div data-testid="balance-info">
        <div data-testid="user-balance" className={styles.userBalance}>
          <div data-testid="balance-title" className={styles.balanceTitle}>
            Ваш баланс pro.finansy:
          </div>
          <div data-testid="balance-sum" className={styles.balanceSum}>
            {userBalance?.toLocaleString('ru-RU') || 0} ₽
          </div>
        </div>

        <div
          data-testid="available-balance"
          className={styles.availableBalance}
        >
          <div data-testid="available-title" className={styles.balanceTitle}>
            Доступно для списания:
          </div>
          <div data-testid="available-sum" className={styles.balanceSum}>
            {availableSum?.toLocaleString('ru-RU') || 0} ₽
          </div>
        </div>
        {balanceError && (
          <div data-testid="balance-error" className={styles.balanceError}>
            <img
              data-testid="warning-icon"
              src={warning}
              alt=""
              className={styles.warning}
            />
            <span data-testid="error-message">{error}</span>
          </div>
        )}
        {balanceSuc && (
          <div data-testid="balance-success" className={styles.balanceSuc}>
            <span data-testid="success-message">
              🔥 Будет списано с баланса:
            </span>
            <div data-testid="balance-amount" className={styles.balanceAct}>
              {balance?.toLocaleString('ru-RU')} ₽
            </div>
          </div>
        )}
      </div>
      {balance &&
        (balanceSuc ? (
          <button
            data-testid="cancel-balance-button"
            type="button"
            className={styles.balanceBtn}
            onClick={cancelBalance}
          >
            Отменить
          </button>
        ) : (
          <button
            data-testid="deduct-balance-button"
            type="button"
            className={styles.balanceBtn}
            disabled={disabled}
            onClick={handleBalance}
          >
            Списать
          </button>
        ))}
    </div>
  )
}

export default Balance
