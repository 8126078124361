import styles from './Tabs.module.scss'
import React from 'react'

const Tabs = ({ tabs, activeIndex, setActiveIndex, ...restProps }) => {
  const handleClick = (index) => setActiveIndex(index)
  const checkActive = (index) => (activeIndex === index ? styles.active : '')

  return (
    <div className={styles.tabs} {...restProps}>
      {tabs.map((tab, index) => (
        <button
          key={tab.id}
          className={`${styles.tab} ${checkActive(index)}`}
          onClick={() => handleClick(index)}
          type="button"
        >
          {tab.name}
        </button>
      ))}
    </div>
  )
}

export default Tabs
