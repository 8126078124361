import styles from './FormButton.module.scss'
import Loader from './Loader'
import React from 'react'

function FormButton({
  countSalesStatus,
  countSalesLink,
  inputsError,
  price,
  promoEr,
  bindingProduct,
  isLoading,
  limitSalesStatus
}) {
  return countSalesStatus ? (
    limitSalesStatus ? (
      <button
        data-testid="loader-button"
        type="button"
        className={styles.button}
        disabled
      >
        <Loader />
      </button>
    ) : (
      <button
        data-testid="continue-shopping-button"
        type="button"
        className={styles.button}
        onClick={() =>
          countSalesLink ? window.open(countSalesLink, '_self') : history.back()
        }
      >
        Продолжить покупки
      </button>
    )
  ) : (
    <button
      data-testid="submit-button"
      type="submit"
      disabled={
        Object.values(inputsError).some((value) => value === true) ||
        !price ||
        promoEr ||
        isLoading
      }
      className={styles.button}
    >
      {isLoading ? (
        <Loader />
      ) : !bindingProduct ? (
        'Перейти к оплате'
      ) : (
        'ПРИВЯЗАТЬ БАНКОВСКУЮ  КАРТУ'
      )}
    </button>
  )
}

export default FormButton
