import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styles from './Inputs.module.scss'

function EmailInput({ email, setEmail, disEmail, setInputsError }) {
  const [emailError, setEmailError] = useState('E-mail не может быть пустым')
  const [emailDirty, setEmailDirty] = useState(false)

  const location = useLocation()
  const getEmail = new URLSearchParams(location.search).get('email')

  useEffect(() => {
    if (disEmail) {
      setEmail(disEmail)
      setEmailError('')
      setInputsError((prev) => ({ ...prev, email: false }))
    }
    if (getEmail) {
      setEmail(getEmail)
      setEmailError('')
      setInputsError((prev) => ({ ...prev, email: false }))
    }
  }, [getEmail, disEmail])

  function emailHandler(e) {
    setEmail(e.target.value.trim())
    const re =
      /([A-Za-z0-9_\-\.]{1,})+\@([A-Za-z0-9_\-\.]{2,})+\.([A-Za-z]{2,4})/y
    if (!re.test(String(e.target.value).toLowerCase())) {
      setEmailError('Некорректный e-mail')
      setInputsError((prev) => ({ ...prev, email: true }))
    } else {
      setEmailError('')
      setInputsError((prev) => ({ ...prev, email: false }))
    }
    if (e.target.value.length === 0) {
      setEmailError('E-mail не может быть пустым')
      setInputsError((prev) => ({ ...prev, email: true }))
    }
  }

  function blurHandler(e) {
    if (e.target.name === 'email') {
      setEmailDirty(true)
    }
  }

  return (
    <div data-testid="email-input-box" className={styles.inputBox}>
      <label data-testid="email-label" id="email-label" htmlFor="email-input">
        E-mail
      </label>
      <input
        data-testid="email-input"
        id="email-input"
        onChange={(e) => emailHandler(e)}
        value={email}
        onBlur={(e) => blurHandler(e)}
        name="email"
        className={`${styles.input} ${!emailError && styles.inputSuccess}`}
        type="text"
        required
        disabled={disEmail}
        placeholder="E-mail"
      />
      {emailDirty && emailError && (
        <div data-testid="email-error-text" className={styles.errorText}>
          {emailError}
        </div>
      )}
    </div>
  )
}

export default EmailInput
