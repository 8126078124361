import { getCurrencySymbol } from '../../../../utils/getCurrencySymbol'
import styles from './RecurrentDescription.module.scss'
import { declOfNum } from '../../../../utils/declOfNum'
import React, { useMemo } from 'react'

function RecurrentDescription({ result, countSalesStatus, currency }) {
  const isIpOrAck = process.env.REACT_APP_IS_IP_OR_ACK === 'true'

  const recStatus = result?.recurrent_status ?? false
  const recurrentStartDay = result?.recurrent_start_day
  const startDayDelayStatus = result?.start_day_delay_status
  const frameId = result?.id
  const recInterval = result?.recurrent_interval
  const recPeriod = result?.recurrent_period
  const recSpecificDate = result?.recurrent_specific_date
  const formattedDate =
    result?.recurrent_specific_date_status && recSpecificDate
      ? new Date(recSpecificDate)?.toLocaleDateString('ru-RU')
      : ''

  const recPrice = useMemo(() => {
    return result?.frame_recurrent_products?.reduce(
      (sum, el) =>
        el.price === null ? sum + el.product_info.price : sum + el.price,
      0
    )
  }, [result])

  const recDescription = result?.recurrent_description

  const interval = useMemo(() => {
    switch (recInterval) {
      case 'Day':
        return 'дней'

      case 'Week':
        return 'недели'

      case 'Month':
        return declOfNum(recPeriod, ['месяц', 'месяца', 'месяцев'])
    }
  }, [recInterval, recPeriod])

  return recStatus && recDescription ? (
    <div data-testid="rec-description" className={styles.recurrent}>
      {recDescription}
    </div>
  ) : (
    <>
      {!countSalesStatus && recStatus && recurrentStartDay ? (
        startDayDelayStatus ? (
          frameId === 1815 ? (
            <div
              data-testid="subscription-payment-delay"
              className={styles.recurrent}
            >{`Платеж по подписке будет списан через ${recurrentStartDay} дней. Сумма платежа составит ${recPrice?.toLocaleString('ru-RU')} ${
              getCurrencySymbol(currency) || '₽'
            }.`}</div>
          ) : frameId === 2518 ||
            frameId === 2521 ||
            frameId === 2524 ||
            frameId === 2527 ||
            frameId === 2530 ||
            frameId === 2533 ? (
            <div className={styles.recurrent}>
              {`Первый платеж ${recPrice?.toLocaleString('ru-RU')} ${
                getCurrencySymbol(currency) || '₽'
              } спишется автоматически через 7 дней. Далее платежи будут списываться каждые 3 месяца. Управлять платежами можно в`}{' '}
              <a href={'https://profinansy.ru/account/profile'} target="_blank">
                личном кабинете.
              </a>
            </div>
          ) : (
            <div
              data-testid="first-subscription-payment"
              className={styles.recurrent}
            >{`Первый платеж по подписке будет списан через ${recurrentStartDay} ${declOfNum(recurrentStartDay, ['день', 'дня', 'дней'])}. Далее стоимость подписки составит ${recPrice?.toLocaleString('ru-RU')} ${
              getCurrencySymbol(currency) || '₽'
            } ${recPeriod === 12 && recInterval === 'Month' ? 'ежегодно' : 'ежемесячно'}.`}</div>
          )
        ) : (
          <div
            data-testid="subscription-cost"
            className={styles.recurrent}
          >{`Далее стоимость подписки составит ${recPrice?.toLocaleString('ru-RU')} ${
            getCurrencySymbol(currency) || '₽'
          }. Платеж будет списываться ${recurrentStartDay}-го числа месяца.`}</div>
        )
      ) : (
        recStatus &&
        (formattedDate ? (
          <div
            data-testid="next-payment-date"
            className={styles.recurrent}
          >{`Следующий платеж ${recPrice?.toLocaleString('ru-RU')} ${
            getCurrencySymbol(currency) || '₽'
          } спишется автоматически ${formattedDate}.`}</div>
        ) : (
          <div data-testid="next-payment-period" className={styles.recurrent}>
            {`Следующий платеж ${recPrice?.toLocaleString('ru-RU')} ${
              getCurrencySymbol(currency) || '₽'
            } спишется автоматически через ${recPeriod} ${interval}. Управлять платежами можно в`}{' '}
            {isIpOrAck ? (
              'личном кабинете.'
            ) : (
              <a href={'https://profinansy.ru/account/profile'} target="_blank">
                личном кабинете.
              </a>
            )}
          </div>
        ))
      )}
    </>
  )
}

export default RecurrentDescription
