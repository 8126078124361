import styles from './Thanks.module.scss'
import React from 'react'

function Fail({ errorCode }) {
  const isIpOrAck = process.env.REACT_APP_IS_IP_OR_ACK === 'true'
  return (
    <div
      data-testid="modal-thanks"
      className={`${styles.modalThanks} ${styles.active}`}
    >
      <div
        data-testid="modal-content-thanks"
        className={`${styles.modal__contentThanks} ${styles.active}`}
      >
        {!isIpOrAck && (
          <img
            data-testid="logo"
            src="/logo.png"
            alt=""
            className={styles.logoThanks}
          />
        )}
        <div data-testid="main-thanks" className={styles.mainThanks}>
          {errorCode !== 401 && (
            <div data-testid="header-thanks" className={styles.headerThanks}>
              Ошибка!
            </div>
          )}
          {errorCode === 401 ? (
            <div data-testid="title-thanks-auth" className={styles.titleThanks}>
              Ошибка авторизации, пожалуйста войдите в свой аккаунт
              profinansy.ru и повторите попытку
            </div>
          ) : (
            <div
              data-testid="title-thanks-payment"
              className={styles.titleThanks}
            >
              К сожалению, ваш платеж отклонен.
            </div>
          )}
          <img
            data-testid="cancel-icon"
            src="/cancel.png"
            alt=""
            className={styles.successLogo}
          />
          {errorCode !== 401 && (
            <div data-testid="retry-message" className={styles.titleThanks}>
              Повторите попытку позже
            </div>
          )}

          {!isIpOrAck && (
            <div data-testid="footer-thanks" className={styles.footerThanks}>
              <p data-testid="footer-text" className={styles.footerText}>
                Если у Вас не получилось самостоятельно решить проблему,
              </p>
              <a
                data-testid="support-link"
                href="https://t.me/profinansy_help_bot"
                className={styles.support}
              >
                свяжитесь с нами
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Fail
