import styles from './FormHeader.module.scss'
import React from 'react'

function FormHeader({ finsovetnik, bindingProduct }) {
  const isIpOrAck = process.env.REACT_APP_IS_IP_OR_ACK === 'true'

  return (
    <>
      {isIpOrAck ? null : finsovetnik ? (
        <img
          data-testid="fs-logo"
          src="/FSlogo.png"
          alt=""
          className={styles.FSlogo}
        />
      ) : (
        <img
          data-testid="default-logo"
          src="/logo.png"
          alt=""
          className={styles.logo}
        />
      )}
      {!bindingProduct ? (
        <div
          data-testid="order-processing-header"
          className={styles.frameHeader}
        >
          Оформление заказа
        </div>
      ) : (
        <div data-testid="card-binding-header" className={styles.frameHeader}>
          Привязка карты
        </div>
      )}
    </>
  )
}

export default FormHeader
