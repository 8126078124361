import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getFrameById } from '../../store/actions/frame'
import { useDispatch, useSelector } from 'react-redux'
import React, { useState, useEffect } from 'react'
import { declOfNum } from '../../utils/declOfNum'
import warning from '../../icons/attention.svg'
import Promocode from '../Promocode/Promocode'

import styles from './Cart.module.scss'
import queryString from 'query-string'

function Cart({
  promoRes,
  setPromoRes,
  promocode,
  setPromocode,
  setPromocode_id
}) {
  const [checkedState, setCheckedState] = useState([])
  const [price, setPrice] = useState(0)
  const [lastPrice, setLastPrice] = useState(0)
  const [total, setTotal] = useState(0)
  const [checkAll, setCheckAll] = useState(false)
  const [btnDisabled, setBtnDisabled] = useState(true)
  const [activeAttention, setActiveAttention] = useState(false)
  const [attention, setAttention] = useState(
    'Для оформления заказа выберите минимум 1 продукт'
  )
  const [cartProduct, setCartProduct] = useState([])

  const navigate = useNavigate()
  const result = useSelector((state) => state.result)
  const { id } = useParams()
  const dispatch = useDispatch()
  const location = useLocation()
  const query = queryString.parse(location.search)

  const [checkedProducts, setCheckedProducts] = useState(
    Array.isArray(query.product)
      ? query.product?.map((el) => `product=${el}`).join('&')
      : query.product
          ?.split()
          .map((el) => `product=${el}`)
          .join('&')
  )

  const isIpOrAck = process.env.REACT_APP_IS_IP_OR_ACK === 'true'

  useEffect(() => {
    dispatch(getFrameById(id, checkedProducts))
  }, [])

  useEffect(() => {
    setCheckedProducts(cartProduct.join('&'))
  }, [cartProduct])

  const products = result?.frame_products
  const promocode_status = result?.promocode_status
  const old_price = promoRes?.products?.reduce((sum, el) => sum + el.price, 0)
  const sale_price = promoRes?.products?.reduce(
    (sum, el) => sum + el.price_difference,
    0
  )

  useEffect(() => {
    setLastPrice(promoRes.success ? old_price - sale_price : price)
  }, [old_price, sale_price, price])

  useEffect(() => {
    if (checkedProducts === undefined || checkedProducts === '') {
      const array = [...new Array(products?.length).fill(false)]
      const arr = []
      products?.map((el, i) => {
        if (el.required === true) {
          array.splice(i, 1, true)
          arr.push(el)
          setTotal(arr.length)
          setBtnDisabled(false)
          setPrice(
            arr.reduce(
              (sum, el) =>
                el.price === null
                  ? sum + el.product_info.price
                  : sum + el.price,
              0
            )
          )
          setCartProduct((prev) => [...prev, `product=${el.product_id}`])
        }
      })
      setCheckedState(array)
    } else {
      const array = [...new Array(products?.length).fill(true)]
      setCartProduct(checkedProducts.split('&'))
      setCheckedState(array)
      setTotal(array.length)
      setPrice(
        products?.reduce(
          (sum, el) =>
            el.price === null ? sum + el.product_info.price : sum + el.price,
          0
        )
      )
      setBtnDisabled(false)
      products?.map((el) => {
        if (el.required === true) {
          setCartProduct((prev) => [...prev, `product=${el.product_id}`])
        }
      })
    }
  }, [products])

  function handleAll(e) {
    if (e.target.checked) {
      setCheckAll(true)
      setCheckedState(checkedState.fill(true))
      setPrice(
        products?.reduce(
          (sum, price, i) =>
            price.price === null
              ? sum + price.product_info.price
              : sum + price.price,
          0
        )
      )
      setTotal(products?.length)
      setBtnDisabled(false)
      setActiveAttention(false)
      setCartProduct(products?.map((el) => `product=${el.product_id}`))
    } else {
      const arr = []
      const array = [...new Array(products?.length).fill(false)]
      products?.map((el, i) => {
        if (el.required === true) {
          array.splice(i, 1, true)
          arr.push(el)
        }
      })
      setCheckAll(false)
      setCheckedState(array)
      setBtnDisabled(arr.length ? false : true)
      setActiveAttention(arr.length ? false : true)
      setCartProduct(
        arr.length ? arr.map((el) => `product=${el.product_id}`) : []
      )
      setTotal(arr.length)
      setPrice(
        arr.reduce(
          (sum, el) =>
            el.price === null ? sum + el.product_info.price : sum + el.price,
          0
        )
      )
    }
  }

  function handleOnChange(position, e) {
    const updatedCheckedState = checkedState?.map((item, index) =>
      index === position ? !item : item
    )
    setCheckedState(updatedCheckedState)

    const totalPrice = updatedCheckedState.reduce(
      (sum, currentState, index) => {
        if (currentState === true) {
          return products[index].price === null
            ? sum + products[index].product_info.price
            : sum + products[index].price
        } else return sum
      },
      0
    )
    setPrice(totalPrice)

    if (e.target.checked) {
      setTotal(total + 1)
      setBtnDisabled(false)
      setActiveAttention(false)
      setCartProduct((prev) => [...prev, `product=${e.target.value}`])
    } else {
      setTotal(total === 0 ? 0 : total - 1)
      setBtnDisabled(total === 1 ? true : false)
      setActiveAttention(total === 1 ? true : false)
      setCartProduct((prev) =>
        prev.filter((x) => x !== `product=${e.target.value}`)
      )
    }
  }

  useEffect(() => {
    if (checkedState.every((el) => el === true)) {
      setCheckAll(true)
    } else {
      setCheckAll(false)
    }
  }, [checkedState])

  function handleSubmit(e) {
    e.preventDefault()
    if (btnDisabled) {
      setActiveAttention(true)
      return
    }

    setActiveAttention(false)
    setAttention('')

    const baseUrl = `/${id}?${cartProduct.join('&')}`
    const searchWithoutCheckedProducts = location.search.replace(
      `?${checkedProducts}`,
      ''
    )
    const searchWithAmpersand = location.search.replace('?', '&')

    let newUrl = baseUrl

    if (promocode === '') {
      newUrl += location.search.includes(checkedProducts)
        ? searchWithoutCheckedProducts
        : searchWithAmpersand
    } else {
      const promoCodeLower = promocode.toLowerCase()
      const promoCodeQuery = `&promocode=${promoCodeLower}`

      if (location.search.includes(checkedProducts)) {
        newUrl += location.search.includes(promoCodeLower)
          ? searchWithoutCheckedProducts
          : promoCodeQuery + searchWithoutCheckedProducts
      } else {
        newUrl += location.search.includes(promoCodeLower)
          ? searchWithAmpersand
          : promoCodeQuery + searchWithAmpersand
      }
    }

    navigate(newUrl)
  }

  return (
    <div data-testid="cart" className={`${styles.cart} ${styles.active}`}>
      <div
        data-testid="cart-content"
        className={`${styles.cartContent} ${styles.active}`}
      >
        {!isIpOrAck && (
          <img
            data-testid="logo"
            src="/logo.png"
            alt=""
            className={styles.logo}
          />
        )}
        <div data-testid="order-header" className={styles.header}>
          Ваш заказ: {total}{' '}
          {declOfNum(total, ['продукт', 'продукта', 'продуктов'])}
        </div>

        {activeAttention && (
          <div data-testid="attention" className={styles.attention}>
            <img
              data-testid="warning-icon"
              src={warning}
              alt=""
              className={styles.warning}
            />
            <span>{attention}</span>
          </div>
        )}

        {promocode_status && (
          <Promocode
            promoRes={promoRes}
            setPromoRes={setPromoRes}
            btnDisabled={btnDisabled}
            checkedProducts={checkedProducts}
            promocode={promocode}
            setPromocode={setPromocode}
            setPromocode_id={setPromocode_id}
          />
        )}

        <div data-testid="choose-all" className={styles.chooseAll}>
          {promoRes.success !== true && (
            <>
              <input
                data-testid="select-all-checkbox"
                className={styles.checkbox}
                name="checkbox"
                type="checkbox"
                onChange={(e) => handleAll(e)}
                checked={checkAll}
              />
              <label
                htmlFor="select-all-checkbox"
                className={styles.chooseText}
              >
                Выбрать все
              </label>
            </>
          )}
        </div>

        {promoRes.success ? (
          <div
            data-testid="promo-products-cart"
            className={styles.productsCart}
          >
            {promoRes.products?.map((el, i) => (
              <label key={i} data-testid={`promo-product-${i}`}>
                <div className={styles.productsChecked}>
                  {el.required ? (
                    <input
                      data-testid={`promo-checkbox-${i}`}
                      className={styles.checkbox}
                      name="checkbox"
                      type="checkbox"
                      value={el.product_id}
                      checked={true}
                      disabled
                    />
                  ) : (
                    <input
                      data-testid={`promo-checkbox-${i}`}
                      className={styles.checkbox}
                      name="checkbox"
                      type="checkbox"
                      value={el.product_id}
                      checked={true}
                      disabled
                    />
                  )}
                  <div
                    className={styles.product}
                    data-testid="products-name-box"
                  >
                    <div
                      className={styles.productTitle}
                      data-testid="products-name"
                    >
                      {el.name}
                    </div>
                    <div
                      className={styles.productDescription}
                      data-testid="products-description"
                    >
                      {el.description}
                    </div>
                  </div>
                  <div>
                    <div
                      className={styles.cartProdPrice}
                      data-testid="products-price-box"
                    >
                      {el.new_price < 0
                        ? 0
                        : el.new_price?.toLocaleString('ru-RU')}{' '}
                      ₽
                    </div>
                    {el.price_difference !== 0 && (
                      <div
                        className={styles.cartOldPrice}
                        data-testid="products-old_price-box"
                      >
                        <div
                          className={styles.oldPriceC}
                          data-testid="products-old_price"
                        >
                          {' '}
                          {el.price?.toLocaleString('ru-RU')} ₽
                        </div>
                        {promoRes.discount_percent_status ? (
                          <div
                            className={styles.priceDifferenceC}
                            data-testid="price-difference-percent"
                          >{`-${promoRes.discount}%`}</div>
                        ) : (
                          <div
                            data-testid="price-difference-count"
                            className={styles.priceDifferenceC}
                          >{`-${el.price_difference} ₽`}</div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </label>
            ))}
          </div>
        ) : (
          <div data-testid="products-cart" className={styles.productsCart}>
            {products?.map((el, i) => (
              <label key={i} data-testid={`product-${i}`}>
                <div
                  className={
                    checkedState[i] ? styles.productsChecked : styles.products
                  }
                >
                  {el.required ? (
                    <input
                      data-testid={`product-checkbox-${i}`}
                      className={styles.checkbox}
                      name="checkbox"
                      type="checkbox"
                      value={el.product_id}
                      checked={true}
                      disabled
                    />
                  ) : (
                    <input
                      data-testid={`product-checkbox-${i}`}
                      className={styles.checkbox}
                      name="checkbox"
                      type="checkbox"
                      value={el.product_id}
                      checked={checkedState[i] || checkAll}
                      onChange={(e) => handleOnChange(i, e)}
                    />
                  )}
                  <div
                    className={styles.product}
                    data-testid="products-title-content"
                  >
                    <div
                      className={styles.productTitle}
                      data-testid="products-title"
                    >
                      {el.name === null
                        ? el.product_info.custom_name === null
                          ? el.product_info.name
                          : el.product_info.custom_name
                        : el.name}
                    </div>
                    <div
                      className={styles.productDescription}
                      data-testid="products-desc-content"
                    >
                      {el.description === null
                        ? el.product_info.description
                        : el.description}
                    </div>
                  </div>
                  <div
                    className={styles.productPrice}
                    data-testid="products-price-content"
                  >
                    {(el.price === null
                      ? el.product_info.price
                      : el.price
                    )?.toLocaleString('ru-RU')}{' '}
                    ₽
                  </div>
                </div>
              </label>
            ))}
          </div>
        )}

        <div className={styles.priceRowC} data-testid="price-summary">
          <div className={styles.priceC} data-testid="price-summary-box">
            <span className={styles.sumC}>Итого:</span>
            <span className={styles.sumPriceC}>
              {lastPrice?.toLocaleString('ru-RU')} ₽
            </span>
          </div>
          {promoRes.success && (
            <div
              className={styles.sumPromoC}
              data-testid="price-summary-old-box"
            >
              <div className={styles.oldSumC} data-testid="price-summary-old">
                <div
                  className={styles.sumC}
                  data-testid="price-summary-old-title"
                >
                  Цена без скидки:
                </div>
                <div
                  className={styles.oldPriceC}
                  data-testid="price-summary-old-count"
                >
                  {old_price?.toLocaleString('ru-RU')} ₽
                </div>
              </div>
              <div className={styles.saleC} data-testid="price-sale-box">
                <div className={styles.sumC} data-testid="price-sale-title">
                  {' '}
                  Скидка:
                </div>
                <div
                  className={styles.saleSumC}
                  data-testid="price-sale-count-box"
                >
                  <div
                    className={styles.salePriceC}
                    data-testid="price-sale-count"
                  >
                    {sale_price?.toLocaleString('ru-RU')} ₽
                  </div>
                  {promoRes.discount_percent_status ? (
                    <div
                      data-testid="price-dif-box"
                      className={styles.priceDifferenceC}
                    >{`-${promoRes.discount}%`}</div>
                  ) : (
                    <div
                      data-testid="price-dif-content"
                      className={styles.priceDifferenceC}
                    >
                      {`-${sale_price?.toLocaleString('ru-RU')} ₽`}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <button
          type="submit"
          data-testid="cart-sub-btn"
          className={btnDisabled ? styles.btnDisabled : styles.btn}
          onClick={(e) => handleSubmit(e)}
        >
          Перейти к оформлению заказа
        </button>
      </div>
    </div>
  )
}

export default Cart
