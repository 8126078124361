import styles from './InstalmentButton.module.scss'
import Loader from '../FormButton/Loader'

import React from 'react'

const InstalmentButton = ({
  isLoading,
  handleClickInstalment,
  btnDisabled
}) => {
  return (
    <button
      data-testid="instalment-button"
      type="button"
      onClick={(e) => handleClickInstalment(e)}
      disabled={btnDisabled}
      className={styles.button}
    >
      {isLoading ? <Loader primary /> : 'Рассрочка для граждан СНГ'}
    </button>
  )
}

export default InstalmentButton
