import styles from './Thanks.module.scss'
import React from 'react'

function SuccessPayForCart() {
  const isIpOrAck = process.env.REACT_APP_IS_IP_OR_ACK === 'true'
  return (
    <div
      data-testid="modal-thanks"
      className={`${styles.modalThanks} ${styles.active}`}
    >
      <div
        data-testid="modal-content-thanks"
        className={`${styles.modal__contentThanks} ${styles.active}`}
      >
        {!isIpOrAck && (
          <img
            data-testid="logo"
            src="/logo.png"
            alt="Logo"
            className={styles.logoThanks}
          />
        )}
        <div data-testid="main-thanks" className={styles.mainThanks}>
          <div data-testid="header-thanks" className={styles.headerThanks}>
            Спасибо!
          </div>
          <div data-testid="title-thanks" className={styles.titleThanks}>
            Ваш заказ оплачен
          </div>
          <div data-testid="email-thanks" className={styles.emailThanks}>
            Письмо-подтверждение отправлено на электронную почту
          </div>
          <img
            data-testid="success-logo"
            src="/thanks.png"
            alt="Success"
            className={styles.successLogo}
          />
          <div data-testid="close-instruction" className={styles.titleThanks}>
            Закройте это окно для возврата в магазин
          </div>
        </div>
      </div>
    </div>
  )
}

export default SuccessPayForCart
