import ResetPasswordForm from '../ResetPasswordForm/ResetPasswordForm'
import { recover } from '../../../store/actions/user&balance'
import Typography from '../components/Typography/Typography'
import FormInput from '../components/FormInput/FormInput'
import { InitialValues, SigninSchema } from './formik'
import styles from './ForgotPasswordForm.module.scss'
import React, { useEffect, useState } from 'react'
import Button from '../components/Button/Button'
import ReCAPTCHA from 'react-google-recaptcha'
import 'react-phone-number-input/style.css'
import { useFormik } from 'formik'

const ForgotPasswordForm = ({ setType, setLoginModalActive, setUserKey }) => {
  const [captcha, setCaptcha] = useState('')

  const [change, setChange] = useState(false)

  const [error, setError] = useState({})
  const [isError, setIsError] = useState(false)

  const urlOrgin = new URL(window.location.href).origin
  const finsovetnik =
    urlOrgin === process.env.REACT_APP_PROD_FA_URL ||
    urlOrgin === process.env.REACT_APP_TEST_FA_URL

  const formik = useFormik({
    initialValues: InitialValues,
    validationSchema: SigninSchema,
    onSubmit: async (values) => {
      try {
        await recover(values, finsovetnik)
        setIsError(false)
      } catch (err) {
        setError(err.response)
        setIsError(true)
      }
    },
    validateOnMount: true
  })

  useEffect(() => {
    if (isError && error.data.code === 401002) {
      setChange(true)
    }
  }, [isError, error])

  const handleCaptcha = (val) => {
    setCaptcha(val)
    formik.setFieldValue('captcha_v2', val)
  }

  return (
    <section
      data-testid="forgot-password-page"
      className={styles['forgot-password-page']}
    >
      {change ? (
        <ResetPasswordForm
          login={formik.values.login}
          recoverFormik={formik}
          setType={setType}
          setLoginModalActive={setLoginModalActive}
          setUserKey={setUserKey}
        />
      ) : (
        <div data-testid="form-container" className={styles['form-container']}>
          <Typography data-testid="form-title" as="h4" className={styles.title}>
            Восстановление доступа
          </Typography>
          <Typography
            data-testid="form-description"
            as="p"
            color="#898989"
            className={styles.description}
          >
            На вашу почту придёт код подтверждения для восстановления пароля
          </Typography>
          <div data-testid="form-fields" className={styles['form-fields']}>
            <FormInput
              data-testid="email-input"
              type="email"
              name="email"
              label="Email"
              placeholder="E-mail"
              className={
                isError ? `${styles.input} ${styles.invalid}` : styles.input
              }
              onChange={(e) => {
                formik.setFieldValue('login', e.target.value)
              }}
            />
            {isError && error.data.code === 403025 && (
              <>
                <Typography
                  data-testid="email-error"
                  className={styles['code-invalid']}
                  color="#F84708"
                >
                  Данный адрес не зарегестрирован!
                </Typography>
                <Typography
                  data-testid="email-suggestion"
                  className={styles['code-invalid']}
                  color="#F84708"
                >
                  Попробуйте другую почту
                </Typography>
              </>
            )}
          </div>
          <div data-testid="recaptcha-container" className={styles.recaptcha}>
            <ReCAPTCHA
              sitekey="6Lft9gIhAAAAAC6Q6D3EQMVJMEXC5vG18Chth6Ym"
              onChange={handleCaptcha}
              data-testid="forgot-recaptcha"
            />
          </div>
          <Button
            data-testid="forgot-submit-button"
            type="primary"
            width="100%"
            backgroundColor="#4285F4"
            onClick={formik.handleSubmit}
            isDisabled={!captcha}
          >
            <Typography className={styles['button-text']}>Отправить</Typography>
          </Button>
          <div data-testid="forgot-footer" className={styles.footer}>
            <Typography data-testid="no-account-text" color="#C0C0C0">
              Нет аккаунта?
            </Typography>
            <button
              data-testid="forgot-signup-button"
              className={styles.login}
              onClick={() => setType('signup')}
              type="button"
            >
              <Typography color="#4285F4">Зарегистрируйтесь</Typography>
            </button>
          </div>
        </div>
      )}
    </section>
  )
}

export default ForgotPasswordForm
